<template>
  <div class="flex-shrink-0 select-none">
    <div
      class="rounded-full flex font-medium items-center justify-center text-white"
      :class="[sizeClasses, colorClasses]"
    >
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { selectedColors, setColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "EmployeeAvatar",
  props: {
    text: {
      type: String,
      requred: true,
      validator: (prop: string) => prop.length <= 2
    },
    color: {
      type: String,
      default: "blue",
      validator: (prop: string) => selectedColors.includes(prop)
    },
    size: {
      type: String,
      default: "md",
      validator: (prop: string) => ["xs", "sm", "md", "lg", "xl"].includes(prop)
    }
  },
  setup(props) {
    const sizeClasses = computed(() => {
      return {
        xs: "h-6 w-6 text-xs",
        sm: "h-8 w-8 text-sm",
        md: "h-10 w-10 text-lg",
        lg: "h-12 w-12 text-2xl",
        xl: "h-14 w-14 text-2xl"
      }[props.size as "sm" | "md" | "lg"];
    });

    const colorClasses = computed(() => {
      return `bg-${setColor(props.color, 500)}`;
    });

    return { colorClasses, sizeClasses };
  }
});
</script>
