import gql from "graphql-tag";

import { employeeFullFragment } from "@/graphql/employees/fragments/employeeFull";

// get employee by id
export const EMPLOYEE_FULL = gql`
  query employeeFull($employeeId: uuid!, $tenantId: uuid!) {
    users(where: { id: { _eq: $employeeId }, tenant_id: { _eq: $tenantId } }) {
      ...EmployeeFull
    }
  }
  ${employeeFullFragment.employeeFull}
`;
