import gql from "graphql-tag";

import { employeeBaseFragment } from "@/graphql/employees/fragments/employeeBase";

// update employee
export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $employeeId: uuid!
    $tenantId: uuid!
    $firstName: String!
    $lastName: String
    $abbreviation: String!
    $color: String
  ) {
    update_users(
      where: { id: { _eq: $employeeId }, tenant_id: { _eq: $tenantId } }
      _set: { first_name: $firstName, last_name: $lastName, abbreviation: $abbreviation, color: $color }
    ) {
      returning {
        ...EmployeeBase
      }
    }
  }
  ${employeeBaseFragment.employeeBase}
`;
