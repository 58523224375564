
import { defineComponent, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import EmployeeAvatar from "@/features/employees/EmployeeAvatar.vue";
import ColorPicker from "@/components/ColorPicker.vue";
// store
import useEmployeeStore, { createAbbreviation } from "@/store/useEmployeeStore";
// other
import useEmployeeForm from "../useEmployeeForm";

export default defineComponent({
  name: "EmployeeDetailsSectionProfileGeneral",
  components: {
    DetailsSection,
    EmployeeAvatar,
    ColorPicker
  },
  props: {
    employeeId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // get employee
    const {
      employee,
      onEmployeeFullResult,
      onUpdateEmployee,
      onUpdateEmployeeSuccess,
      loading,
      error
    } = useEmployeeStore({
      employeeId: props.employeeId
    });

    // set form parameters after each component rendering
    const employeeFirstName = computed(() => (employee.value ? employee.value.first_name : ""));
    const employeeLastName = computed(() => (employee.value?.last_name ? employee.value.last_name : ""));
    const employeeAbbreviation = computed(() => (employee.value ? employee.value.abbreviation : ""));
    const employeeColor = computed(() => (employee.value ? employee.value.color : ""));

    /**
     * form
     */
    const { formData, employeeUpdateSchema, validate, firstNameSchema, lastNameSchema } = useEmployeeForm({
      firstName: employeeFirstName.value,
      lastName: employeeLastName.value,
      abbreviation: employeeAbbreviation.value,
      color: employeeColor.value
    });

    // placeholder for abbreviation
    const employeeAbbreviationPlaceholder = computed(() => {
      if (formData.firstName) return createAbbreviation(formData.firstName, formData.lastName);
      else createAbbreviation(employeeFirstName.value, employeeLastName.value);
    });

    // display for profile icon
    const employeeAvatarText = computed(() => {
      if (formData.abbreviation === "") return employeeAbbreviationPlaceholder.value;
      else return formData.abbreviation.toUpperCase();
    });

    // set employee color with color picker
    const setColor = (color: string) => {
      formData.color = color;
    };

    // set form values to original graphql query values
    const resetFormValues = () => {
      formData.firstName = employeeFirstName.value;
      formData.lastName = employeeLastName.value;
      formData.abbreviation = employeeAbbreviation.value;
      formData.color = employeeColor.value;
    };

    // set values after result is queried
    onEmployeeFullResult(() => {
      resetFormValues();
    });

    // check if form values have been updated
    const isFormUnchanged = computed(() => {
      if (
        formData.firstName === employeeFirstName.value &&
        formData.lastName === employeeLastName.value &&
        (formData.abbreviation === employeeAbbreviation.value || formData.abbreviation === "") &&
        formData.color === employeeColor.value
      )
        return true;
      else return false;
    });

    // Validate and create employee
    const onSubmit = () => {
      // set abbreviation
      formData.abbreviation = employeeAvatarText.value ? employeeAvatarText.value : formData.abbreviation;
      // check if abbreviation is set
      employeeUpdateSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onUpdateEmployee(formData.firstName, formData.lastName, formData.abbreviation, formData.color);
        })
        .catch(() => {
          validate.value = true;
        });
    };

    onUpdateEmployeeSuccess(() => {
      console.info("employee updated");
    });

    return {
      formData,
      validate,
      employee,
      onSubmit,
      resetFormValues,
      firstNameSchema,
      lastNameSchema,
      employeeAbbreviationPlaceholder,
      employeeAvatarText,
      employeeFirstName,
      isFormUnchanged,
      loading,
      error,
      setColor
    };
  }
});
