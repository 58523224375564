
import { defineComponent, ref, computed, PropType } from "vue";
// components
import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";

// other
import { Users } from "@/graphql/types";

export default defineComponent({
  name: "EmployeeDeleteButton",
  components: {
    DeleteConfirmationModal
  },
  props: {
    employee: { type: Object as PropType<Users>, required: true }
  },
  emits: {
    deleted: null,
    canceled: null
  },
  setup(props, context) {
    const { onDeleteEmployee, onDeleteEmployeeSuccess, loading, fullName } = useEmployeeStore({ allEmployees: true });

    /**
     * Confirmation dialoge
     */

    // confirmation diaglog visibility
    const deleteConfirmationVisible = ref(false);
    const onClickDelete = () => {
      deleteConfirmationVisible.value = true;
    };

    // confirmation dialog message
    const header = computed(() => `Delete ${fullName(props.employee)}`);
    const message = `Are you really sure you want to delete ${fullName(
      props.employee
    )} and all related shifts? This action can't be undone.`;

    /**
     * Confirmation dialoge actions
     */

    const onConfirmDelete = () => {
      onDeleteEmployee(props.employee.id);
    };

    const onCancelDelete = () => {
      deleteConfirmationVisible.value = false;
      context.emit("canceled");
    };

    onDeleteEmployeeSuccess(() => {
      deleteConfirmationVisible.value = false;
      context.emit("deleted");
      console.info("Employee deleted");
    });

    return {
      header,
      message,
      onClickDelete,
      deleteConfirmationVisible,
      loading,
      onConfirmDelete,
      onCancelDelete
    };
  }
});
