import gql from "graphql-tag";

// delete employee
export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($employeeId: uuid!, $tenantId: uuid!) {
    delete_users(where: { id: { _eq: $employeeId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
