import gql from "graphql-tag";

import { employeeFullFragment } from "@/graphql/employees/fragments/employeeFull";

// create new employee with contract
export const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $firstName: String!
    $lastName: String
    $abbreviation: String!
    $color: String!
    $tenantId: uuid!
    $weeklyWorkingHours: Int!
  ) {
    insert_users_one(
      object: {
        first_name: $firstName
        last_name: $lastName
        abbreviation: $abbreviation
        color: $color
        roles: { data: { name: employee, tenant_id: $tenantId } }
        tenant_id: $tenantId
        contracts: { data: { tenant_id: $tenantId, weekly_working_hours: $weeklyWorkingHours } }
      }
    ) {
      ...EmployeeFull
    }
  }
  ${employeeFullFragment.employeeFull}
`;
