
import { defineComponent, computed } from "vue";
// components
import EmployeeDetailsPageProfileGeneral from "./EmployeeDetailsPageProfileGeneral.vue";
import EmployeeDetailsPageProfileContracts from "./EmployeeDetailsPageProfileContracts.vue";
import EmployeeDeleteButton from "@/features/employees/EmployeeDeleteButton.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
// other
import { useRoute, useRouter } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeDetailsPageProfile",
  components: {
    EmployeeDetailsPageProfileGeneral,
    EmployeeDetailsPageProfileContracts,
    EmployeeDeleteButton
  },
  setup() {
    // get employee id
    const {
      params: { employeeId: employeeUrlId }
    } = useRoute();
    const employeeId = computed(() => repairUuid(employeeUrlId));

    // get employee
    const { employee } = useEmployeeStore({ employeeId: employeeId.value });

    // router for redirect after delete
    const router = useRouter();

    return { employeeId, employee, router };
  }
});
