import gql from "graphql-tag";

import { employeeBaseFragment } from "@/graphql/employees/fragments/employeeBase";

export const employeeFullFragment = {
  employeeFull: gql`
    fragment EmployeeFull on users {
      ...EmployeeBase
      roles {
        id
        name
      }
      contracts {
        id
        weekly_working_hours
      }
    }
    ${employeeBaseFragment.employeeBase}
  `
};
