import gql from "graphql-tag";

export const employeeBaseFragment = {
  employeeBase: gql`
    fragment EmployeeBase on users {
      id
      first_name
      last_name
      abbreviation
      color
      email
    }
  `
};
