import gql from "graphql-tag";

import { employeeFullFragment } from "@/graphql/employees/fragments/employeeFull";

// get all users with role employee
export const EMPLOYEES_FULL = gql`
  query employeesFull($tenantId: uuid!) {
    users(where: { tenant_id: { _eq: $tenantId }, roles: { name: { _eq: employee } } }) {
      ...EmployeeFull
    }
  }
  ${employeeFullFragment.employeeFull}
`;
