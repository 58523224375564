
import { defineComponent, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
// other
import useEmployeeForm from "../useEmployeeForm";

export default defineComponent({
  name: "EmployeeDetailsSectionProfileGeneral",
  components: {
    DetailsSection
  },
  props: {
    employeeId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // get employee
    const {
      employee,
      onEmployeeFullResult,
      onUpdateContract,
      onUpdateContractSuccess,
      loading,
      error
    } = useEmployeeStore({
      employeeId: props.employeeId
    });
    // get contracts
    const employeeContracts = computed(() => (employee.value ? employee.value.contracts : []));
    // get working hours
    const employeeWeeklyWorkingHours = computed(() =>
      employeeContracts.value.length > 0 ? employeeContracts.value[0].weekly_working_hours.toString() : "0"
    );

    /**
     * form
     */
    const { formData, weeklyWorkingHoursSchema, validate, updateContractSchema } = useEmployeeForm({
      weeklyWorkingHours: employeeWeeklyWorkingHours.value
    });

    // set form values to original graphql query values
    const resetFormValues = () => {
      formData.weeklyWorkingHours = employeeWeeklyWorkingHours.value;
    };
    // set values after result is queried
    onEmployeeFullResult(() => {
      resetFormValues();
    });

    // check if form values have been updated
    const isFormUnchanged = computed(() => {
      if (formData.weeklyWorkingHours === employeeWeeklyWorkingHours.value) return true;
      else return false;
    });

    // Validate and create employee
    const onSubmit = () => {
      // check if abbreviation is set
      updateContractSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onUpdateContract(employeeContracts.value[0].id, formData.weeklyWorkingHours);
        })
        .catch(() => {
          validate.value = true;
        });
    };

    onUpdateContractSuccess(() => {
      console.info("Employee contract updated");
    });

    return {
      formData,
      validate,
      weeklyWorkingHoursSchema,
      resetFormValues,
      isFormUnchanged,
      loading,
      onSubmit,
      error
    };
  }
});
