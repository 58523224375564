import { computed } from "vue";
// Graphql
import useAuth from "@/api/auth/useAuth";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { EmployeeFullQuery, EmployeesFullQuery, Users, EmployeeFullFragment } from "@/graphql/types";
import { EMPLOYEES_FULL } from "@/graphql/employees/queries/employeesFull";
import { EMPLOYEE_FULL } from "@/graphql/employees/queries/employeeFull";
import { DELETE_EMPLOYEE } from "@/graphql/employees/mutations/deleteEmployee";
import { UPDATE_EMPLOYEE } from "@/graphql/employees/mutations/updateEmployee";
import { UPDATE_CONTRACT } from "@/graphql/employees/mutations/updateContract";
import { CREATE_EMPLOYEE } from "@/graphql/employees/mutations/createEmployee";
// other
import produce from "immer";

/**
 ***************************
 * Helper functions
 ***************************
 */

/**
 * Compose full name of first and last name
 */
export function createFullName(firstName: string | null, lastName: string | null | undefined) {
  if (firstName) return lastName ? `${firstName} ${lastName}` : firstName;
  else return "";
}

/**
 * Create abbreviation of first two letters (or first letter) of employee name
 */
export function createAbbreviation(firstName: string | null, lastName: string | null) {
  if (firstName) {
    const abbreviation = lastName ? `${firstName[0]}${lastName[0]}` : firstName[0];
    return abbreviation.toUpperCase();
  } else return "";
}

/**
 ***************************
 * Store hook
 ***************************
 */

export default function useEmployeeStore(options?: { employeeId?: string; allEmployees?: boolean }) {
  const { tenantId } = useAuth();

  /**
   * Query all employees
   */

  const employeesFullQueryVariables = computed(() => {
    return { tenantId: tenantId.value };
  });

  const { result: employeesFullResult, loading: employeesFullLoading, error: employeesFullError } = useQuery<
    EmployeesFullQuery
  >(EMPLOYEES_FULL, employeesFullQueryVariables.value, () => ({
    enabled: options?.allEmployees === true
  }));
  const employees = useResult(employeesFullResult, [], data => data.users);

  /**
   * Query single employee
   */

  // query variables
  const employeeFullQueryVariables = computed(() => {
    return { tenantId: tenantId.value, employeeId: options?.employeeId };
  });
  // get employee
  const {
    result: employeeFullResult,
    loading: employeeFullLoading,
    error: employeeFullError,
    onResult: onEmployeeFullResult
  } = useQuery<EmployeeFullQuery>(EMPLOYEE_FULL, employeeFullQueryVariables.value, () => ({
    enabled: options?.employeeId != undefined
  }));
  const employee = useResult(employeeFullResult, null, data => data.users[0]);

  /**
   * Create employee
   */

  // create graphql mutation objects
  const {
    mutate: createEmployee,
    loading: createEmployeeLoading,
    error: createEmployeeError,
    onDone: onCreateEmployeeSuccess
  } = useMutation(CREATE_EMPLOYEE, {
    update: (cache, { data: { insert_users_one } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const employeesData = cache.readQuery<EmployeesFullQuery>({
        query: EMPLOYEES_FULL,
        variables: employeesFullQueryVariables.value
      });

      // add employee to users list
      const employeesUpdate = produce(employeesData?.users, draftState => {
        // add employee to state
        draftState?.push(insert_users_one);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: EMPLOYEES_FULL,
        variables: employeesFullQueryVariables.value,
        data: { users: employeesUpdate }
      });
    }
  });

  const onCreateEmployee = (
    firstName: string,
    lastName: string,
    abbreviation: string,
    color: string,
    weeklyWorkingHours: string
  ) => {
    createEmployee({
      firstName: firstName,
      lastName: lastName,
      abbreviation: abbreviation,
      color: color,
      weeklyWorkingHours: weeklyWorkingHours,
      tenantId: tenantId.value
    });
  };

  /**
   * Delete employee
   */

  const {
    mutate: deleteEmployee,
    loading: deleteEmployeeLoading,
    error: deleteEmployeeError,
    onDone: onDeleteEmployeeSuccess
  } = useMutation(DELETE_EMPLOYEE, {
    update: (cache, { data: { delete_users } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const employeesData = cache.readQuery<EmployeesFullQuery>({
        query: EMPLOYEES_FULL,
        variables: employeesFullQueryVariables.value
      });

      // get id
      const employeeId = delete_users.returning.length > 0 ? delete_users.returning[0].id : null;

      // remove employee to users list
      const employeesUpdate = produce(employeesData?.users, draft => {
        // add employee to state
        return draft?.filter(user => user.id !== employeeId);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: EMPLOYEES_FULL,
        variables: employeesFullQueryVariables.value,
        data: { users: employeesUpdate }
      });
    }
  });

  const onDeleteEmployee = (employeeId: string) => deleteEmployee({ tenantId: tenantId.value, employeeId: employeeId });

  /**
   * Update employee contract
   */

  const {
    mutate: updateContract,
    loading: updateContractLoading,
    error: updateContractError,
    onDone: onUpdateContractSuccess
  } = useMutation(UPDATE_CONTRACT);

  const onUpdateContract = (contractId: string, weeklyWorkingHours: string) => {
    updateContract({
      contractId: contractId,
      weeklyWorkingHours: weeklyWorkingHours,
      tenantId: tenantId.value
    });
  };

  /**
   * Update employee
   */

  const {
    mutate: updateEmployee,
    loading: updateEmployeeLoading,
    error: updateEmployeeError,
    onDone: onUpdateEmployeeSuccess
  } = useMutation(UPDATE_EMPLOYEE);

  const onUpdateEmployee = (firstName: string, lastName: string, abbreviation: string, color: string) => {
    updateEmployee({
      firstName: firstName,
      lastName: lastName,
      abbreviation: abbreviation,
      color: color,
      employeeId: options?.employeeId,
      tenantId: tenantId.value
    });
  };

  /**
   * Status
   */

  const loading = computed(() => {
    if (employeesFullLoading.value === true) return true;
    else if (employeeFullLoading.value === true) return true;
    else if (deleteEmployeeLoading.value === true) return true;
    else if (updateContractLoading.value === true) return true;
    else if (updateEmployeeLoading.value === true) return true;
    else if (createEmployeeLoading.value === true) return true;
    else return false;
  });

  const error = computed(() => {
    if (employeesFullError.value) return employeesFullError.value;
    else if (employeeFullError.value) return employeeFullError.value;
    else if (deleteEmployeeError.value) return deleteEmployeeError.value;
    else if (updateContractError.value) return updateContractError.value;
    else if (updateEmployeeError.value) return updateEmployeeError.value;
    else if (createEmployeeError.value) return createEmployeeError.value;
    else return null;
  });

  /**
   * Other
   */

  const fullName = (employee: Users | EmployeeFullFragment | null) => {
    if (employee) return createFullName(employee.first_name, employee.last_name);
    else return "";
  };

  return {
    // status
    loading,
    error,
    // queries
    employees,
    employee,
    onEmployeeFullResult,
    fullName,
    // create
    onCreateEmployee,
    onCreateEmployeeSuccess,
    // delete
    onDeleteEmployee,
    onDeleteEmployeeSuccess,
    // update
    onUpdateContract,
    onUpdateContractSuccess,
    onUpdateEmployee,
    onUpdateEmployeeSuccess
  };
}
