import gql from "graphql-tag";

// update employee
export const UPDATE_CONTRACT = gql`
  mutation updateContract($contractId: uuid!, $tenantId: uuid!, $weeklyWorkingHours: Int!) {
    update_contract(
      where: { id: { _eq: $contractId }, tenant_id: { _eq: $tenantId } }
      _set: { weekly_working_hours: $weeklyWorkingHours }
    ) {
      returning {
        id
        weekly_working_hours
      }
    }
  }
`;
